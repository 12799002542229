package ui

import androidx.compose.foundation.gestures.Orientation
import androidx.compose.foundation.gestures.rememberScrollableState
import androidx.compose.foundation.gestures.scrollable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.widthIn
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.LazyListState
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.material.OutlinedButton
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.drawBehind
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.text.font.FontFamily
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.TextUnit
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.compose.ui.unit.times
import models.Bar
import models.Chord
import models.Note
import models.NoteOptions
import models.Rest
import models.Score
import models.Spacer
import models.Text
import kotlin.math.max
import kotlin.math.roundToInt

@Composable
fun ScoreRenderer(
  modifier: Modifier = Modifier,
  score: Score,
  staffSize: TextUnit = 32.sp,
  fontFamily: FontFamily,
  onClose: (() -> Unit)? = null,
) {
  Column(verticalArrangement = Arrangement.spacedBy(8.dp)) {
    OutlinedButton(
      onClick = { onClose?.invoke() }
    ) {
      Text(text = "Back")
    }

    Box(
      Modifier.fillMaxWidth()
    ) {
      Text(
        modifier = Modifier.align(Alignment.Center),
        text = score.songInfo.title,
        fontSize = 32.sp
      )
    }

    Row {
      Text(
        modifier = Modifier.weight(1F),
        text = score.songInfo.author
      )

      Text(
        modifier = Modifier.weight(1F),
        text = score.songInfo.lyricist,
        textAlign = TextAlign.Center
      )

      Text(
        modifier = Modifier.weight(1F),
        text = "${score.songInfo.copyright1} ${score.songInfo.copyright2}",
        textAlign = TextAlign.End
      )
    }


    val scoreNotes = score.staffs.map {
      it.notes.toMutableList()
    }
    val noteColumns = mutableListOf<List<Int>>()

    while (scoreNotes.sumOf { it.size } != 0) {
      var longestNotationLength = 1
      scoreNotes.forEach {
        if (it.isNotEmpty()) {
          when (val nextNote = it[0]) {
            is Note -> {
              val length = nextNote.duration.normalizedLength()
              longestNotationLength = max(
                longestNotationLength,
                length
              )
            }

            is Chord -> {
              val length = nextNote.duration.normalizedLength()
              longestNotationLength = max(
                longestNotationLength,
                length
              )
            }

            is Rest -> {
              val length = nextNote.duration.normalizedLength()
              longestNotationLength = max(
                longestNotationLength,
                length
              )
            }

            is Bar -> {

            }

            is Spacer -> {}
            is Text -> {}
          }
        }
      }

      val notesInColumn = scoreNotes.map {
        var notesToRenderPosition = 0
        var notationCount = 0
        while (it.isNotEmpty() && notationCount < longestNotationLength) {
          val note = it.removeFirst()
          when (note) {
            is Note -> {
              val length = note.duration.normalizedLength()
              notationCount += length
            }

            is Chord -> {
              val length = note.duration.normalizedLength()
              notationCount += length
            }

            is Rest -> {
              val length = note.duration.normalizedLength()
              notationCount += length
            }

            else -> {
              notationCount += 1
            }
          }
          notesToRenderPosition++
        }
        notesToRenderPosition
      }
      noteColumns.add(notesInColumn)
    }


    val fontSize = staffSize
    val noteStep = (fontSize / 4)
    val noteHalfStep = noteStep / 2

    val fontPixels = with(LocalDensity.current) { fontSize.toPx() }

    Box(modifier = Modifier.fillMaxSize()) {
      val states = score.staffs.map {
        rememberSaveable(
          saver = LazyListState.Saver,
          key = it.toString()
        ) {
          LazyListState(
            0,
            0
          )
        }
      }

      states.forEach { current ->
        val otherStates = states.toMutableList()
        otherStates.remove(current)
        otherStates.forEach {
          if (!it.isScrollInProgress) {
//                        runBlocking {
//                            if (this.isActive)
//                                it.scrollToItem(current.firstVisibleItemIndex, current.firstVisibleItemScrollOffset)
//                        }
          }
        }
      }

      LazyColumn(
        state = rememberLazyListState(),
        modifier = Modifier
          .fillMaxWidth()
          .padding(start = 8.dp)
          .scrollable(
            state = rememberScrollableState({ it }),
            orientation = Orientation.Vertical
          )
      ) {
        score.staffs.forEachIndexed { index, staff ->
          item {
            LazyRow(
              state = states[index]
            ) {
              //Clef
              item {
                Box(
                  modifier = Modifier
                    .drawBehind {
                      drawLine(
                        color = Color.Black,
                        start = Offset(
                          0F,
                          if (index == 0) fontPixels - 1 else 0F
                        ),
                        end = Offset(
                          0F,
                          ((fontPixels) * if (index == score.staffs.size - 1) 2 else 4) + if (index != score.staffs.size - 1) 1F else 0F
                        )
                      )
                    }
                ) {
//                            Text(
//                                "${staff.name}"
//                            )


                  Text(
                    text = "\uE014\uE014\uE014",
                    fontFamily = fontFamily,
                    fontSize = fontSize
                  )

                  val cleffType = staff.clef.type
                  val cleffUnicode = if (cleffType == "Treble") "\uE050" else "\uE062"
                  val cleffOffset = if (cleffType == "Treble") -noteStep else -noteStep * 3

                  Text(
                    "  ${cleffUnicode}",
                    fontFamily = fontFamily,
                    fontSize = fontSize,
                    modifier = Modifier.offset(y = cleffOffset.textDp())
                  )
                }
              }


              //KeySignature
              item {

              }

              //Time Signature
              item {
                Box {
                  Text(
                    text = "\uE014\uE014\uE014",
                    fontFamily = fontFamily,
                    fontSize = fontSize
                  )

                  Text(
                    text = "\uE09E\uE084\uE09F\uE084",
                    fontFamily = fontFamily,
                    fontSize = fontSize
                  )
                }
              }

              val center = -noteStep * 2

              val tempNotes = staff.notes.toMutableList()
              val staffGroupedNotes = noteColumns.map {
                val notesInColumn = it[index]
                tempNotes.subList(
                  0,
                  notesInColumn
                ).toList().also {
                  it.forEach {
                    tempNotes.removeAt(0)
                  }
                }
              }

//                            val indexAnim = remember { Animatable(1F) }
//                            LaunchedEffect(Unit) {
//                                indexAnim.animateTo(targetValue = staffGroupedNotes.size.toFloat(), tween(10000))
//                            }
              items(staffGroupedNotes.size) {
                Box {
                  var staffLineText = ""
                  for (i in 0 until (noteColumns[it].maxOf { it } * 2)) {
                    staffLineText += "\uE014"
                  }
                  Text(
                    text = staffLineText,
                    fontFamily = fontFamily,
                    fontSize = fontSize
                  )


//                                    console.log("###: ${indexAnim.value}")
                  LazyRow(modifier = Modifier.widthIn(max = 512.dp)) {
                    staffGroupedNotes[it].forEach { note ->
                      item {
                        if (note is Note) {
                          val unModifierPosition = (if (note.position.startsWith("n") ||
                            note.position.startsWith("b") ||
                            note.position.startsWith("#")
                          ) note.position.substring(1) else note.position)

                          var unModifiedEnd =
                            if (unModifierPosition.last() == ')')
                              unModifierPosition.substring(
                                startIndex = 0,
                                endIndex = unModifierPosition.length - 1
                              )
                            else
                              unModifierPosition

                          unModifiedEnd = if (unModifiedEnd.last() == '^') unModifiedEnd.substring(
                            startIndex = 0,
                            endIndex = unModifiedEnd.length - 1
                          ) else unModifiedEnd

                          val staffPosition = -unModifiedEnd.toInt()

                          val stemUp = center > (staffPosition * noteHalfStep)

                          Text(
                            text = "${note.toUnicode(stemUp)}  ",
                            fontFamily = fontFamily,
                            fontSize = fontSize,
                            modifier = Modifier.offset(y = center.textDp() + (staffPosition * noteHalfStep).textDp())
                          )
                        } else if (note is Bar) {
                          Text(
                            text = "\uE030  ",
                            fontFamily = fontFamily,
                            fontSize = fontSize
                          )
                        } else if (note is Rest) {
                          Text(
                            text = note.toUnicode(),
                            fontFamily = fontFamily,
                            fontSize = fontSize,
                            modifier = Modifier.offset(y = center.textDp())
                          )
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

//            HorizontalScrollbar(
//                rememberScrollbarAdapter(states[0]),
//                Modifier.align(Alignment.BottomCenter)
//            )
    }
  }
}

fun Chord.toUnicode(stemUp: Boolean) = toUnicode(
  stemUp,
  duration,
  noteOptions
)

fun Chord.toUnicode2(stemUp: Boolean) = toUnicode(
  stemUp,
  duration2,
  noteOptions
)

fun Note.toUnicode(stemUp: Boolean): String {
  return toUnicode(
    stemUp,
    duration,
    noteOptions
  )
}

public fun toUnicode(
  stemUp: Boolean,
  duration: String,
  noteOptions: NoteOptions?,
): String {
  val stemUp = noteOptions?.stem?.equals("Up") ?: stemUp
  val note = when {
    duration.contains("Whole") -> "\uE1D2"
    duration.contains("Half") -> if (stemUp) "\uE1D3" else "\uE1D4"
    duration.contains("4th") -> if (stemUp) "\uE1D5" else "\uE1D6"
    duration.contains("8th") -> if (stemUp) "\uE1D7" else "\uE1D8"
    duration.contains("16th") -> if (stemUp) "\uE1D9" else "\uE1DA"
    duration.contains("32th") -> if (stemUp) "\uE1DB" else "\uE1DC"
    else -> "\uE014"
  }
  if (duration.contains("Dotted")) {
    return "${note}\uE1E7"
  }
  return note
}

fun Rest.toUnicode(): String {
  val note = when {
    duration.contains("Whole") -> "\uE4E3"
    duration.contains("Half") -> "\uE4E4"
    duration.contains("4th") -> "\uE4E5"
    duration.contains("8th") -> "\uE4E6"
    duration.contains("16th") -> "\uE4E7"
    duration.contains("32th") -> "\uE4E8"
    else -> "\uE014"
  }

  if (duration.contains("Dotted")) {
    return "${note}\uE1E7"
  }
  return note
}

fun String.normalizedLength(): Int {
  val baseValue = when {
    this.contains("Whole") -> 64 * 3
    this.contains("Half") -> 32 * 3
    this.contains("4th") -> 16 * 3
    this.contains("8th") -> 8 * 3
    this.contains("16th") -> 4 * 3
    this.contains("32nd") -> 2 * 3
    else -> 1
  }

  if (this.contains("Dotted")) {
    return baseValue + (baseValue / 2)
  }
  if (this.contains("Trip")) {
    return (baseValue * .666666).roundToInt()
  }
  return baseValue
}

@Composable
private fun TextUnit.textDp() = with(LocalDensity.current) { toDp() }
