
import androidx.compose.animation.core.LinearOutSlowInEasing
import androidx.compose.animation.core.tween
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.gestures.Orientation
import androidx.compose.foundation.gestures.Orientation.Vertical
import androidx.compose.foundation.gestures.animateScrollBy
import androidx.compose.foundation.gestures.draggable
import androidx.compose.foundation.gestures.forEachGesture
import androidx.compose.foundation.gestures.rememberDraggableState
import androidx.compose.foundation.gestures.scrollBy
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.ColumnScope
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.LazyListState
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.BasicTextField
import androidx.compose.material.Divider
import androidx.compose.material.ScrollableTabRow
import androidx.compose.material.Tab
import androidx.compose.material3.OutlinedButton
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.Modifier
import androidx.compose.ui.composed
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.input.pointer.PointerEvent
import androidx.compose.ui.input.pointer.PointerEventPass
import androidx.compose.ui.input.pointer.changedToUp
import androidx.compose.ui.input.pointer.pointerInput
import androidx.compose.ui.semantics.contentDescription
import androidx.compose.ui.semantics.onClick
import androidx.compose.ui.semantics.semantics
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.input.TextFieldValue
import androidx.compose.ui.unit.dp
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.launch
import kotlin.math.abs
import kotlin.math.exp
import kotlin.math.log

@OptIn(ExperimentalComposeUiApi::class)
@Composable
fun CompactHymnalBrowser(
  modifier: Modifier = Modifier,
  hymnals: List<Hymnal>,
  onPlayHymn: (Hymnal.Hymn) -> Unit,
  onCopyToClipboard: (String) -> Unit,
  onOpenLink: (String) -> Unit,
) {
  Column(
    modifier = modifier
  ) {
    var selectedIndex by remember { mutableStateOf((hymnals.size.toFloat() / 2F).toInt()) }

    ScrollableTabRow(
      selectedTabIndex = selectedIndex,
    ) {
      hymnals.forEachIndexed { index, hymnal ->
        Tab(
          selected = index == selectedIndex,
          onClick = { selectedIndex = index },
          text = {
            Text(
              text = hymnal.name
            )
          }
        )
      }
    }

    if (hymnals.isNotEmpty()) {
      val scope = rememberCoroutineScope()
      val lazyColumnState = rememberLazyListState()

      LazyColumn(
        modifier = Modifier
          .fillMaxSize()
          .draggableFling(
            lazyColumnState,
            Vertical
          ),
        state = lazyColumnState
      ) {
        var first = true
        hymnals[selectedIndex].hymns.filter { it.value.hymnTxtPath != null }.forEach { hymn ->
          item {
            if (!first) {
              Divider()
            }
            first = false
            Text(
              modifier = Modifier
                .fillMaxWidth()
                .clickable(onClick = { onPlayHymn(hymn.value) })
                .padding(8.dp),
              text = "${hymn.key} ${hymn.value.lyric?.firstLine}"
            )
          }
        }
      }
    }
  }
}

@Composable
fun FullHymnalBrowser(
  modifier: Modifier = Modifier,
  hymnals: List<Hymnal>,
  onPlayHymn: (Hymnal.Hymn) -> Unit,
  onCopyToClipboard: (String) -> Unit,
  onOpenLink: (String) -> Unit,
) {
  Column(
    modifier = modifier.fillMaxSize().background(Color.LightGray).padding(16.dp)
  ) {
    var selectedHymnal by remember { mutableStateOf(hymnals[0]) }
    var selectedHymn by remember { mutableStateOf<Pair<String, Hymnal.Hymn>?>(null) }
    Row(
      modifier = Modifier.height(250.dp),
      horizontalArrangement = Arrangement.spacedBy(16.dp)
    ) {
      Column {
        Row(modifier = Modifier.width(300.dp)) {
          Text("Hymnal: ")
          HymnalDropdown(
            hymnals = hymnals,
            onHymnalSelected = {
              selectedHymnal = it
              selectedHymn = null
            }
          )
        }

        Spacer(modifier = Modifier.height(50.dp))

        HymnButtons(
          currentHymnal = selectedHymnal,
          currentHymn = selectedHymn,
          onPlayHymn = onPlayHymn,
          onCopyToClipboard = onCopyToClipboard,
          onOpenLink = onOpenLink
        )

        Spacer(modifier = Modifier.height(8.dp))

        Text(
          text = "Meter: ${selectedHymn?.second?.composer?.metrical}"
        )
      }

      HymnSelector(
        Modifier.weight(1F),
        selectedHymnal,
        selectedHymn
      ) { selectedHymn = it }
    }

    Spacer(
      modifier = Modifier.height(16.dp)
    )

    Metadata(
      modifier = Modifier.height(150.dp),
      selectedHymn = selectedHymn
    )

    Spacer(
      modifier = Modifier.height(16.dp)
    )

    BasicSearch(
      hymnal = selectedHymnal
    )
  }
}

@Composable
private fun HymnSelector(
  modifier: Modifier = Modifier,
  selectedHymnal: Hymnal,
  selectedHymn: Pair<String, Hymnal.Hymn>?,
  onHymnSelected: (Pair<String, Hymnal.Hymn>) -> Unit,
) {
  Box(
    modifier = modifier
      .clip(RoundedCornerShape(2.dp))
      .background(Color.White)
      .padding(2.dp)
  ) {
    val state = rememberLazyListState()
    LazyColumn(
      modifier = Modifier.draggableFling(state, Vertical),
      state = state,
    ) {
      items(selectedHymnal.hymns.size) {
        val hymn = selectedHymnal.hymns.entries.toTypedArray()[it]

        Text(
          modifier = Modifier
            .fillMaxWidth()
            .clickable(onClick = {
              onHymnSelected(
                Pair(
                  hymn.key,
                  hymn.value
                )
              )
            })
            .background(if (selectedHymn?.first == hymn.key) Color.Blue else Color.Transparent),
          text = "${hymn.key} ${hymn.value.lyric?.firstLine}",
          color = if (selectedHymn?.first == hymn.key) Color.White else Color.Black
        )
      }
    }


//        androidx.compose.foundation.VerticalScrollbar(
//            rememberScrollbarAdapter(state),
//            Modifier.align(Alignment.CenterEnd)
//        )
  }
}

@Composable
private fun Metadata(
  modifier: Modifier = Modifier,
  selectedHymn: Pair<String, Hymnal.Hymn>?,
) {
  val state = rememberLazyListState()
  Box(
    modifier = modifier.fillMaxSize()
      .clip(RoundedCornerShape(2.dp))
      .background(Color.White)
      .padding(2.dp)
  ) {
    LazyColumn(
      modifier = Modifier.draggableFling(state, Vertical),
      state = state,
      verticalArrangement = Arrangement.spacedBy(2.dp)
    ) {
      item {
        Row {
          Text(
            modifier = Modifier.width(100.dp),
            text = "Title:"
          )

          Text(
            modifier = Modifier.weight(1F),
            text = if (selectedHymn == null)
              ""
            else
              "Hymn ${selectedHymn.first} ${selectedHymn.second.lyric?.firstLine ?: ""}"
          )
        }
      }

      item {
        Row {
          Text(
            modifier = Modifier.width(100.dp),
            text = "Word:"
          )

          Text(
            modifier = Modifier.weight(1F),
            text = selectedHymn?.second?.lyracist?.source ?: ""
          )
        }
      }

      item {
        Row {
          Text(
            modifier = Modifier.width(100.dp),
            text = "Music:"
          )

          Text(
            modifier = Modifier.weight(1F),
            text = selectedHymn?.second?.composer?.composer1 ?: ""
          )
        }
      }

      item {
        Row {
          Text(
            modifier = Modifier.width(100.dp),
            text = "Copyright:"
          )

          Text(
            modifier = Modifier.weight(1F),
            text = selectedHymn?.second?.copyright?.textCR ?: ""
          )
        }
      }

      item {
        Row {
          Text(
            modifier = Modifier.width(100.dp),
            text = "Scripture:"
          )

          Text(
            modifier = Modifier.weight(1F),
            text = selectedHymn?.second?.lyracist?.scripture ?: ""
          )
        }
      }
    }
//
//        VerticalScrollbar(
//            rememberScrollbarAdapter(state),
//            Modifier.align(Alignment.CenterEnd)
//        )
  }
}

@Composable
private fun HymnButtons(
  modifier: Modifier = Modifier,
  currentHymnal: Hymnal,
  currentHymn: Pair<String, Hymnal.Hymn>?,
  onPlayHymn: (Hymnal.Hymn) -> Unit,
  onCopyToClipboard: (String) -> Unit,
  onOpenLink: (String) -> Unit,
) {
  Column(
    verticalArrangement = Arrangement.spacedBy(8.dp),
    modifier = modifier.width(150.dp)
  ) {
//        val scoreFile = currentHymn?.second?.hymnTxtPath?.let { javaClass.classLoader.getResource(it)?.let { File(it.toURI()) } }

    OutlinedButton(
      modifier = Modifier.fillMaxWidth(),
      onClick = { onPlayHymn(currentHymn!!.second) },
      enabled = currentHymn != null //&& scoreFile != null
    ) {
      Text(
        text = "Play File"
      )
    }

    var openDialog by remember { mutableStateOf(false) }
    if (openDialog) {
//            AlertDialog(
//                modifier = Modifier.width(400.dp),
//                onDismissRequest = { openDialog = false },
//                buttons = {
//                    Box(modifier = Modifier.fillMaxWidth()) {
//                        Button(
//                            modifier = Modifier.align(Alignment.Center),
//                            onClick = {
//                                currentHymn?.second?.lyric?.fullText?.let { onCopyToClipboard(it) }
//                            }
//                        ) {
//                            Text(
//                                text = "Clipboard"
//                            )
//                        }
//                    }
//
//                },
//                title = {
//                    Text(text = "${currentHymn?.second?.lyric?.firstLine}")
//                },
//                text = {
//                    Text("${currentHymn?.second?.lyric?.fullText}")
//                }
//            )
    }

    OutlinedButton(
      modifier = Modifier.fillMaxWidth(),
      onClick = { openDialog = true },
      enabled = currentHymn?.second?.lyric != null
    ) {
      Text(
        text = "Hymn Lyrics"
      )
    }

    val baseUrl = when (currentHymnal.name) {
      "1940 Hymnal" -> "https://hymnary.org/hymn/HPEC1940/"
      "1982 Hymnal" -> "https://hymnary.org/hymn/EH1982/"
      "1982 Service Music" -> "https://hymnary.org/hymn/EH1982/"
      "Lift Every Voice and Sing" -> "https://hymnary.org/hymn/LEVS1993/"
      "Wonder Love and Praise" -> "https://hymnary.org/hymn/WLP1997/"
      else -> null
    }

    OutlinedButton(
      modifier = Modifier.fillMaxWidth(),
      onClick = {
        onOpenLink("${baseUrl}${currentHymn?.first}")
      },
      enabled = baseUrl != null && currentHymn != null
    ) {
      Text(
        text = "Hymn Info"
      )
    }
  }
}

@Composable
fun HymnalDropdown(
  modifier: Modifier = Modifier,
  hymnals: List<Hymnal>,
  onHymnalSelected: (Hymnal) -> Unit,
) {
  var expanded by remember { mutableStateOf(false) }
  var selectedIndex by remember { mutableStateOf(0) }
  Box(modifier = modifier) {
    Text(
      hymnals[selectedIndex].name,
      modifier = Modifier.clickable(onClick = { expanded = true })
    )
    DropdownMenu(
      expanded = expanded,
      onDismissRequest = { expanded = false },
      modifier = Modifier.width(250.dp)
    ) {
      if (expanded) {
        hymnals.forEachIndexed { index, hymnal ->
          Box(
            modifier = Modifier
              .padding(4.dp)
              .clip(RoundedCornerShape(16.dp))
              .background(Color.LightGray)
              .clickable {
                selectedIndex = index
                expanded = false
                onHymnalSelected(hymnals[selectedIndex])
              },
          ) {
            Text(text = hymnal.name)
          }
        }
      }
    }
  }
}

@Composable
fun DropdownMenu(
  expanded: Boolean,
  onDismissRequest: () -> Unit,
  modifier: Modifier,
  function: @Composable ColumnScope.() -> Unit,
) {
  Column(
    modifier.expandable(
      { if (expanded) onDismissRequest() },
      "Drop Down"
    )
  ) {
    function()
  }
}

@Composable
fun BasicSearch(
  modifier: Modifier = Modifier,
  hymnal: Hymnal,
) {
  Box(
    modifier = modifier.fillMaxWidth()
      .clip(RoundedCornerShape(2.dp))
      .background(Color.White)
      .padding(8.dp)
  ) {
    Column {
      Text(
        text = "Basic Search"
      )

      Spacer(modifier = Modifier.height(16.dp))

      Row(
        horizontalArrangement = Arrangement.spacedBy(8.dp)
      ) {
        val searchKeyword1 = remember { mutableStateOf(TextFieldValue()) }
        val searchKeyword2 = remember { mutableStateOf(TextFieldValue()) }
        var isSearchAnd by remember { mutableStateOf(true) }

        Column {
          var expanded by remember(hymnal) { mutableStateOf(false) }
          var selectedIndex by remember(hymnal) { mutableStateOf(0) }
          Text(text = "Seasons")

          val items = hymnal.hymns.values
            .map {
              it.lyric?.firstLine?.let {
                val lastIndex = it.indexOf(" -")
                if (lastIndex < 0) return@let null
                it.substring(
                  0,
                  lastIndex
                )
              } ?: "* All"
            }
            .toSet()

          Box(modifier = Modifier) {
            Text(
              text = items.toTypedArray()[selectedIndex],
              modifier = Modifier.clickable(onClick = { expanded = true })
            )
            DropdownMenu(
              expanded = expanded,
              onDismissRequest = { expanded = false },
              modifier = Modifier.width(250.dp)
            ) {
              items.forEachIndexed { index, string ->
                Box(
                  modifier = Modifier.padding(4.dp).clip(RoundedCornerShape(16.dp))
                    .background(Color.LightGray)
                    .clickable {
                      selectedIndex = index
                      expanded = false
                    },
                ) {
                  Text(text = string)
                }
              }
            }
          }
        }

        Column {
          Text(text = "Search Keyword")

          BasicTextField(
            modifier = Modifier.width(100.dp).clip(RoundedCornerShape(8.dp)).background(Color.LightGray)
              .padding(4.dp),
            value = searchKeyword1.value,
            onValueChange = { searchKeyword1.value = it }
          )
        }


        OutlinedButton(
          modifier = Modifier.width(100.dp),
          onClick = {
            isSearchAnd = !isSearchAnd
          }
        ) {
          Text(text = if (isSearchAnd) "AND" else "OR")
        }

        Column {
          Text(text = "Search Keyword")

          BasicTextField(
            modifier = Modifier.width(100.dp).clip(RoundedCornerShape(8.dp)).background(Color.LightGray)
              .padding(4.dp),
            textStyle = TextStyle(),
            value = searchKeyword2.value,
            onValueChange = { searchKeyword2.value = it }
          )
        }

      }
    }
  }
}


private fun Modifier.expandable(
  onExpandedChange: () -> Unit,
  menuLabel: String,
) = pointerInput(Unit) {
  forEachGesture {
    coroutineScope {
      awaitPointerEventScope {
        var event: PointerEvent
        do {
          event = awaitPointerEvent(PointerEventPass.Initial)
        } while (
          !event.changes.all { it.changedToUp() }
        )
        onExpandedChange.invoke()
      }
    }
  }
}.semantics {
  contentDescription = menuLabel // this should be a localised string
  onClick {
    onExpandedChange()
    true
  }
}

private fun Modifier.draggableFling(
  lazyListState: LazyListState,
  orientation: Orientation,
) =
  composed {
    val scope = rememberCoroutineScope()
    val draggableState = rememberDraggableState {
      scope.launch {
        lazyListState.scrollBy(-it)
      }
    }

    this.draggable(
      state = draggableState,
      orientation = orientation,
      onDragStopped = { startVelocity ->

        val time = log(
          x = (VELOCITY_THREASHOLD / abs(startVelocity)).toDouble(),
          base = 10.0
        ) * 1000.0 / FRICTION
        val flingDistance: Double = startVelocity / FRICTION * (exp(FRICTION * time / 1000.0) - 1)
        launch {
          lazyListState.animateScrollBy(
            -flingDistance.toFloat(),
            tween(easing = LinearOutSlowInEasing)
          )
        }
      }
    )
  }

private const val DEFAULT_FRICTION = -4.2f
private const val VELOCITY_THRESHOLD_MULTIPLIER = 1000f / 16f
private const val FRICTION = 1.1f * DEFAULT_FRICTION

private const val THRESHOLD_MULTIPLIER = 0.75f
private const val VELOCITY_THREASHOLD = THRESHOLD_MULTIPLIER * VELOCITY_THRESHOLD_MULTIPLIER
