package sound

import Midi1Player
import dev.atsushieno.ktmidi.GeneralMidi
import dev.atsushieno.ktmidi.MidiChannelStatus
import dev.atsushieno.ktmidi.MidiOutput
import models.Chord
import models.Key.Companion.FLAT
import models.Key.Companion.SHARP
import models.Note
import models.Rest
import models.Score
import ui.normalizedLength


class MusicPlayer(val score: Score, output: MidiOutput) {

    val player = Midi1Player(getMidiMusic(), output, shouldDisposeOutput = true)

    fun getMidiMusic(): MidiMusic {
        var music = MidiMusic()
        music.deltaTimeSpec = 128
        score.staffs.forEach {
            var track = MidiTrack()
            var ch = it.properties.channel
            var device = it.instrument.name
            println("###: $ch : $device")
            track.messages.add(
                MidiMessage(
                    deltaTime = 188, evt = MidiEvent(
                        type = MidiChannelStatus.PROGRAM + ch,
                        arg1 = GeneralMidi.INSTRUMENT_NAMES.indexOf(device) + 1,
                        arg2 = 0,
                        extraData = null,
                        extraOffset = 0,
                        extraLength = 0
                    )
                )
            )
            val measureDuration = 60000F / it.tempo.tempo * it.timeSignature.top / it.timeSignature.bottom


//            val tempoDuration = (60000 / it.tempo.tempo)
            println("###: ${it.tempo.tempo} : $measureDuration")

            val keyPositions = it.key.getKeyPositions(it.clef)
            it.notes.forEach {
                when (it) {
                    is Chord -> {

                    }
                    is Note -> {
                        val unModifierPosition = (if (it.position.startsWith("n") ||
                            it.position.startsWith("b") ||
                            it.position.startsWith("#")
                        ) it.position.substring(1) else it.position)

                        var unModifiedEnd =
                            if (unModifierPosition.last() == ')')
                                unModifierPosition.substring(
                                    startIndex = 0,
                                    endIndex = unModifierPosition.length - 1
                                )
                            else
                                unModifierPosition

                        unModifiedEnd = if (unModifiedEnd.last() == '^') unModifiedEnd.substring(
                            startIndex = 0,
                            endIndex = unModifiedEnd.length - 1
                        ) else unModifiedEnd

                        var staffPosition = -unModifiedEnd.toInt()

                        val found = keyPositions.find { it.second == staffPosition }
                        if (found != null) {
                            if (found.first == SHARP) staffPosition++
                            else if (found.first == FLAT) staffPosition--
                        } else {
                            if (it.position.contains("##")) staffPosition += 2
                            else if (it.position.contains("bb")) staffPosition -= 2
                            else if (it.position.contains("#")) staffPosition++
                            else if (it.position.contains("b")) staffPosition--
                        }
                        val normalizedDuration = it.duration.normalizedLength().toFloat() / "Whole".normalizedLength().toFloat()

//                        println("###: ${it.duration} ${(normalizedDuration * measureDuration).toInt()}")

                        track.messages.add(
                            MidiMessage(
                                deltaTime = 0,
                                evt = MidiEvent(
                                    type = MidiChannelStatus.NOTE_ON + ch,
                                    arg1 = 60 - staffPosition,
                                    arg2 = 120
                                )
                            )
                        )

                        track.messages.add(
                            MidiMessage(
                                deltaTime = (normalizedDuration * measureDuration).toInt(),
                                evt = MidiEvent(
                                    type = MidiChannelStatus.NOTE_OFF + ch,
                                    arg1 = 60 - staffPosition,
                                    arg2 = 0
                                )
                            )
                        )
                    }
                    is Rest -> {

                    }
                    else -> { }
                }
            }

            music.tracks.add(track)
        }

//
//
//
//
//        var track = MidiTrack()
//        var ch = 1
//        println("###: Sax Index: ${GeneralMidi.INSTRUMENT_NAMES.indexOf("Soprano Sax") + 1}")
//        track.messages.add(
//            MidiMessage(
//                188,
//                MidiEvent(
//                    MidiChannelStatus.PROGRAM + ch,
//                    GeneralMidi.INSTRUMENT_NAMES.indexOf("Soprano Sax") + 1,
//                    0,
//                    null,
//                    0,
//                    0
//                )
//            )
//        )
//        for (i in 0 until 100) {
//            track.messages.add(
//                MidiMessage(4, MidiEvent(MidiChannelStatus.NOTE_ON + ch, i, 120, null, 0, 0))
//            )
//            track.messages.add(
//                MidiMessage(88, MidiEvent(MidiChannelStatus.NOTE_OFF + ch, i, 0, null, 0, 0))
//            )
//        }


        return music
    }
}
