package models

class NwcTxtParser {

    val lines: List<String>

    constructor(input: String) {
        lines = input.lines()
    }

    fun parse(): Score {
        val scoreBuilder = Score.Builder()

        lines.forEach {
            if (it.contains("NoteWorthyCompose") || it.isEmpty()) return@forEach

            val splitLine = it.split("|")

            val propertyMap = mutableMapOf<String, String>()
            splitLine.subList(2, splitLine.size).forEach {
                val keyValue = it.split(":")
                propertyMap[keyValue[0]] = keyValue[1]
            }

            val command = splitLine[1]

            with(command) {
                when {
                    contains("Editor") -> {
                        parseEditor(propertyMap)
                    }
                    contains("SongInfo") -> {
                        scoreBuilder.songInfo = parseSongInfo(propertyMap)
                    }
                    contains("PgSetup") -> {
                        scoreBuilder.pageSetup = parsePageSetup(propertyMap)
                    }
                    contains("PgMargins") -> {
                        scoreBuilder.pageMargins = parsePageMargins(propertyMap)
                    }
                    contains("Font") -> {
                        scoreBuilder.addFont(parseFont(propertyMap))
                    }
                    contains("AddStaff") -> {
                        scoreBuilder.addStaff(parseAddStaff(propertyMap))
                    }
                    contains("StaffProperties") -> {
                        scoreBuilder.addStaffProperties(scoreBuilder.currentStaffProperties.parseStaffProperties(propertyMap))
                    }
                    contains("Instrument") -> {
                        scoreBuilder.addStaffInstrument(parseStaffInstrument(propertyMap))
                    }
                    contains("Key") -> {
                        scoreBuilder.addKey(parseKey(propertyMap))
                    }
                    contains("Clef") -> {
                        scoreBuilder.addStaffClef(parseClef(propertyMap))
                    }
                    contains("Tempo") -> {
                        scoreBuilder.addTempo(parseTempo(propertyMap))
                    }
                    contains("TimeSig") -> {
                        scoreBuilder.addTimeSignature(parseTimeSignature(propertyMap))
                    }
                    contains("Text") -> {
                        scoreBuilder.addNotation(parseText(propertyMap))
                    }
                    contains("Note") -> {
                        scoreBuilder.addNotation(parseNote(propertyMap))
                    }
                    contains("Chord") -> {
                        scoreBuilder.addNotation(parseChord(propertyMap))
                    }
                    contains("Bar") -> {
                        scoreBuilder.addNotation(parseBar(propertyMap))
                    }
                    contains("Ba2") -> {
                        scoreBuilder.addNotation(parseBar(propertyMap))
                    }
                    contains("Lyrics") -> {
                        scoreBuilder.addLyrics(parseLyrics(propertyMap))
                    }
                    contains("Lyric") -> {
                        scoreBuilder.addLyric(parseLyric(propertyMap))
                    }
                    contains("Rest") -> {
                        scoreBuilder.addNotation(parseRest(propertyMap))
                    }
                    contains("Spacer") -> {
                        scoreBuilder.addNotation(parseSpacer(propertyMap))
                    }
                }
            }
        }

        return scoreBuilder.build()
    }

    private fun parseEditor(properties: Map<String, String>) =
        Editor(
            activeStaff = properties["ActiveStaff"]?.toInt() ?: 0,
            caretIndex = properties["CaretIndex"]?.toInt() ?: 0,
            caretPosition = properties["CaretPos"]?.toInt() ?: 0
        )


    private fun parseSongInfo(properties: Map<String, String>) =
        SongInfo(
            title = properties["Title"] ?: "",
            author = properties["Author"] ?: "",
            lyricist = properties["Lyricist"] ?: "",
            copyright1 = properties["Copyright1"] ?: "",
            copyright2 = properties["Copyright2"] ?: ""
        )

    private fun parsePageSetup(properties: MutableMap<String, String>) =
        PageSetup(
            staffSize = properties["StaffSize"]?.toInt() ?: 0,
            zoom = properties["Zoom"]?.toInt() ?: 0,
            titlePage = properties["TitlePage"]?.equals("Y") ?: false,
            justifyVertically = properties["JustifyVertically"]?.equals("Y") ?: false,
            printSystemSepMark = properties["PrintSystemSepMark"]?.equals("Y") ?: false,
            extendLastSystem = properties["ExtendLastSystem"]?.equals("Y") ?: false,
            durationPadding = properties["DurationPadding"]?.equals("Y") ?: false,
            pageNumbers = properties["PageNumbers"]?.toInt() ?: 0,
            staffLabels = properties["StaffLabels"] ?: "None",
            barNumbers = properties["BarNumbers"] ?: "Plain",
            startingBar = properties["StartingBar"]?.toInt() ?: 0
        )

    private fun parseFont(properties: MutableMap<String, String>) =
        Font(
            style = properties["Style"] ?: "",
            typeface = properties["Typeface"] ?: "",
            size = properties["Size"]?.toInt() ?: 0,
            bold = properties["Bold"]?.equals("Y") ?: false,
            italic = properties["Italic"]?.equals("Y") ?: false,
            charSet = properties["CharSet"]?.toInt() ?: 1
        )

    private fun parsePageMargins(properties: MutableMap<String, String>) =
        PageMargins(
            left = properties["Left"]?.toDouble() ?: 0.0,
            right = properties["Right"]?.toDouble() ?: 0.0,
            top = properties["Top"]?.toDouble() ?: 0.0,
            bottom = properties["Bottom"]?.toDouble() ?: 0.0,
            mirror = properties["Mirror"]?.equals("Y") ?: false,
        )

    private fun parseAddStaff(properties: MutableMap<String, String>) =
        AddStaff(
            name = properties["Name"] ?: "",
            group = properties["Group"] ?: ""
        )

    private fun StaffProperties?.parseStaffProperties(properties: MutableMap<String, String>) =
        StaffProperties(
            endingBar = properties["EndingBar"] ?: this?.endingBar ?: "",
            visible = properties["Visible"]?.equals("Y") ?: this?.visible ?: false,
            boundaryTop = properties["BoundaryTop"]?.toInt() ?: this?.boundaryTop ?: 0,
            boundaryBottom = properties["BoundaryBottom"]?.toInt() ?: this?.boundaryBottom ?: 0,
            lines = properties["Lines"]?.toInt() ?: this?.lines ?: 0,
            color = properties["Color"] ?: this?.color ?: "Default",
            withNextStaff = properties["WithNextStaff"] ?: this?.withNextStaff ?: "",
            muted = properties["Muted"]?.equals("Y") ?: this?.muted ?: false,
            volume = properties["Volume"]?.toInt() ?: this?.volume ?: 0,
            stereoPan = properties["StereoPan"]?.toInt() ?: this?.stereoPan ?: 0,
            device = properties["Device"]?.toInt() ?: this?.device ?: 0,
            channel = properties["Channel"]?.toInt() ?: this?.channel ?: 0
        )

    private fun parseStaffInstrument(properties: MutableMap<String, String>) =
        StaffInstrument(
            name = properties["Name"] ?: "",
            patch = properties["Patch"]?.toInt() ?: 0,
            pos = properties["Pos"]?.toInt() ?: 0,
            trans = properties["Trans"]?.toInt() ?: 0,
            dynVel = properties["DynVel"] ?: ""
        )

    private fun parseKey(properties: MutableMap<String, String>) =
        Key(
            signature = properties["Signature"]?.split(",") ?: emptyList(),
            tonic = properties["Tonic"] ?: "C?"
        )

    private fun parseClef(properties: MutableMap<String, String>) =
        Clef(
            type = properties["Type"] ?: "Treble"
        )

    private fun parseTempo(properties: MutableMap<String, String>) =
        Tempo(
            base = properties["Base"] ?: "",
            tempo = properties["Tempo"]?.toInt() ?: 0,
            position = properties["position"]?.toInt() ?: 0
        )

    private fun parseTimeSignature(properties: MutableMap<String, String>) =
        TimeSignature(
            top = properties["Signature"]?.split("/")?.get(0)?.toInt() ?: 4,
            bottom = properties["Signature"]?.split("/")?.get(1)?.toInt() ?: 4
        ).also {
            println("###: $properties : $it")
        }

    private fun parseText(properties: MutableMap<String, String>) =
        Text(
            text = properties["Text"] ?: "",
            font = properties["Font"] ?: "",
            position = properties["Position"]?.toInt() ?: 0
        )

    private fun parseNote(properties: MutableMap<String, String>) =
        Note(
            duration = properties["Dur"] ?: "",
            position = properties["Pos"] ?: "",
            noteOptions = properties["Opts"]?.let {
                val optionsMap = mutableMapOf<String, String>()
                it.split(",").forEach {
                    val keyValue = it.split("=")
                    optionsMap[keyValue[0]] = if (keyValue.size == 1) "Yes" else keyValue[1]
                }

                NoteOptions(
                    stem = optionsMap["Stem"] ?: "",
                    slur = optionsMap["Slur"] ?: if (it.contains("Slur")) "Slur" else "",
                    tie = optionsMap["Tie"] ?: if (it.contains("Tie")) "Tie" else "",
                    beam = optionsMap["Beam"] ?: if (it.contains("Beam")) "Beam" else ""
                )
            }
        )

    private fun parseChord(properties: MutableMap<String, String>) =
        Chord(
            duration = properties["Dur"] ?: "",
            duration2 = properties["Dur2"] ?: "",
            positions = properties["Pos"]?.split(",") ?: emptyList(),
            positions2 = properties["Pos2"]?.split(",") ?: emptyList(),
            noteOptions = properties["Opts"]?.let {
                val optionsMap = mutableMapOf<String, String>()
                it.split(",").forEach {
                    val keyValue = it.split("=")
                    optionsMap[keyValue[0]] = if (keyValue.size == 1) "Yes" else keyValue[1]
                }

                NoteOptions(
                    stem = optionsMap["Stem"] ?: "",
                    slur = optionsMap["Slur"] ?: "",
                    tie = optionsMap["Tie"] ?: "",
                    beam = optionsMap["Beam"] ?: ""
                )
            }
        )

    private fun parseBar(properties: MutableMap<String, String>) =
        Bar(
            style = properties["Style"] ?: "",
            repeat = properties["Repeat"]?.toInt() ?: 0
        )

    private fun parseLyrics(properties: MutableMap<String, String>) =
        Lyrics(
            placement = properties["Placement"] ?: "",
            align = properties["Align"] ?: "",
            offset = properties["Offset"]?.toInt() ?: 0
        )

    private fun parseLyric(properties: MutableMap<String, String>) =
        Lyric(
            text = properties["Text"] ?: ""
        )

    private fun parseRest(properties: MutableMap<String, String>) =
        Rest(
            duration = properties["Dur"] ?: ""
        )

    private fun parseSpacer(properties: MutableMap<String, String>) =
        Spacer(
            width = properties["Width"]?.toInt() ?: 0
        )
}

@kotlinx.serialization.Serializable
data class Score(
    val songInfo: SongInfo,
    val pageSetup: PageSetup,
    val pageMargins: PageMargins,
    val fonts: MutableList<Font> = mutableListOf(),
    val staffs: MutableList<Staff> = mutableListOf()
) {
    @kotlinx.serialization.Serializable
    class Staff(
        val name: String,
        val group: String,
        val properties: StaffProperties,
        val instrument: StaffInstrument,
        val clef: Clef,
        val tempo: Tempo,
        val timeSignature: TimeSignature,
        val notes: List<Notation>,
        val lyrics: Lyrics?,
        val lyric: List<Lyric>?,
        val key: Key
    )

    class Builder {
        var songInfo: SongInfo? = null
        var pageSetup: PageSetup? = null
        var pageMargins: PageMargins? = null
        val fonts: MutableList<Font> = mutableListOf()
        val staffs: MutableList<Staff> = mutableListOf()

        private var currentStaffTempo: Tempo? = null

        private var currentStaff: AddStaff? = null
        var currentStaffProperties: StaffProperties? = null
        private var currentStaffInstrument: StaffInstrument? = null
        private var currentStaffClef: Clef? = null
        private var currentStaffTimeSignature: TimeSignature? = null
        private var currentStaffNotations: MutableList<Notation> = mutableListOf()
        private var currentStaffLyrics: Lyrics? = null
        private var currentStaffLyric: MutableList<Lyric> = mutableListOf()
        private var currentKey: Key? = null

        fun addFont(font: Font) {
            fonts.add(font)
        }

        fun addStaff(addStaff: AddStaff): Builder {
            if (currentStaff != null) createStaff()
            currentStaff = addStaff
            return this
        }

        fun addStaffProperties(staffProperties: StaffProperties) {
            currentStaffProperties = staffProperties
        }

        fun addStaffInstrument(staffInstrument: StaffInstrument) {
            currentStaffInstrument = staffInstrument
        }

        fun addStaffClef(clef: Clef) {
            currentStaffClef = clef
        }

        fun addTempo(tempo: Tempo) {
            currentStaffTempo = tempo
        }

        fun addKey(key: Key) {
            currentKey = key
        }

        fun addTimeSignature(timeSignature: TimeSignature) {
            currentStaffTimeSignature = timeSignature
        }

        fun addNotation(notation: Notation) {
            currentStaffNotations.add(notation)
        }

        fun addLyrics(lyrics: Lyrics?) {
            currentStaffLyrics = lyrics
        }

        fun addLyric(lyric: Lyric) {
            currentStaffLyric.add(lyric)
        }

        private fun createStaff() {
            staffs.add(
                Staff(
                    name = currentStaff!!.name,
                    group = currentStaff!!.group,
                    properties = currentStaffProperties!!,
                    instrument = currentStaffInstrument!!,
                    clef = currentStaffClef!!,
                    key = currentKey ?: Key(emptyList(), ""),
                    tempo = currentStaffTempo ?: Tempo("Half", 60, 12),
                    timeSignature = currentStaffTimeSignature ?: TimeSignature(4, 4),
                    notes = currentStaffNotations,
                    lyrics = currentStaffLyrics,
                    lyric = currentStaffLyric
                )
            )

            currentStaff = null
            currentStaffClef = null
            currentStaffProperties = null
            currentStaffInstrument = null
            currentKey = null
            currentStaffTimeSignature = null
            currentStaffNotations = mutableListOf()
            currentStaffLyrics = null
            currentStaffLyric = mutableListOf()
        }

        fun build(): Score {
            if (currentStaff != null) {
                createStaff()
            }

            return Score(
                songInfo = songInfo!!,
                pageSetup = pageSetup!!,
                pageMargins = pageMargins!!,
                fonts = fonts,
                staffs = staffs
            )
        }
    }
}

@kotlinx.serialization.Serializable
data class Editor(
    val activeStaff: Int,
    val caretIndex: Int,
    val caretPosition: Int
)

@kotlinx.serialization.Serializable
data class SongInfo(
    val title: String,
    val author: String,
    val lyricist: String,
    val copyright1: String,
    val copyright2: String
)

@kotlinx.serialization.Serializable
data class PageSetup(
    val staffSize: Int,
    val zoom: Int,
    val titlePage: Boolean,
    val justifyVertically: Boolean,
    val printSystemSepMark: Boolean,
    val extendLastSystem: Boolean,
    val durationPadding: Boolean,
    val pageNumbers: Int,
    val staffLabels: String,
    val barNumbers: String,
    val startingBar: Int
)

@kotlinx.serialization.Serializable
data class Font(
    val style: String,
    val typeface: String,
    val size: Int,
    val bold: Boolean,
    val italic: Boolean,
    val charSet: Int
)

@kotlinx.serialization.Serializable
data class PageMargins(
    val left: Double,
    val top: Double,
    val right: Double,
    val bottom: Double,
    val mirror: Boolean
)

@kotlinx.serialization.Serializable
data class AddStaff(
    val name: String,
    val group: String
)

@kotlinx.serialization.Serializable
data class StaffProperties(
    val endingBar: String,
    val visible: Boolean,
    val boundaryTop: Int,
    val boundaryBottom: Int,
    val lines: Int,
    val withNextStaff: String,
    val color: String,

    val muted: Boolean,
    val volume: Int,
    val stereoPan: Int,
    val device: Int,
    val channel: Int
)

@kotlinx.serialization.Serializable
data class StaffInstrument(
    val name: String,
    val patch: Int,
    val trans: Int,
    val pos: Int,
    val dynVel: String
)

@kotlinx.serialization.Serializable
data class Clef(
    val type: String
)

//|Key|Signature:F#,C#|Tonic:D
@kotlinx.serialization.Serializable
data class Key(
    val signature: List<String>,
    val tonic: String
) {
    fun getKeyPositions(cleff: Clef): List<Pair<String, Int>> {
        return signature.mapIndexed { index, it ->
            val unicode = when {
                it.contains("#") -> SHARP
                it.contains("b") -> FLAT
                else -> NATURAL
            }

            return@mapIndexed if (cleff.type == "Treble")
                unicode to (if (unicode == SHARP) TREBLE_SHARP_POS else TREBLE_FLAT_POS)[index]
            else
                unicode to (if (unicode == SHARP) BASS_SHARP_POS else BASS_FLAT_POS)[index]
        }
    }

    companion object {
        val SHARP = "\uE262"
        val FLAT = "\uE260"
        val NATURAL = "\uE261"

        private val TREBLE_SHARP_POS = listOf(4, 1, 5, 2, -1)
        private val TREBLE_FLAT_POS = listOf(0, 3, -1, 2, -2, 1)

        private val BASS_SHARP_POS = listOf(2, -1, 3, 0, -3)
        private val BASS_FLAT_POS = listOf(-2, 1, -3, 0, -4, -1)
    }
}

@kotlinx.serialization.Serializable
data class Tempo(
    val base: String,
    val tempo: Int,
    val position: Int
)

@kotlinx.serialization.Serializable
data class TimeSignature(
    val top: Int,
    val bottom: Int
)

public fun Int.toUnicode() =
    when (this) {
        1 -> "\uE081"
        2 -> "\uE082"
        3 -> "\uE083"
        4 -> "\uE084"
        5 -> "\uE085"
        6 -> "\uE086"
        7 -> "\uE087"
        8 -> "\uE088"
        9 -> "\uE089"
        else -> "\uE080"
    }

@kotlinx.serialization.Serializable
data class Text(
    val text: String,
    val font: String,
    val position: Int
) : Notation()

@kotlinx.serialization.Serializable
sealed class Notation

@kotlinx.serialization.Serializable
data class Note(
    val duration: String,
    val position: String,
    val noteOptions: NoteOptions?
) : Notation()

@kotlinx.serialization.Serializable
data class NoteOptions(
    val stem: String,
    val slur: String,
    val tie: String,
    val beam: String
)

@kotlinx.serialization.Serializable
data class Chord(
    val duration: String,
    val duration2: String,
    val positions: List<String>,
    val positions2: List<String>,
    val noteOptions: NoteOptions?
) : Notation()

@kotlinx.serialization.Serializable
data class Bar(
    val style: String,
    val repeat: Int
) : Notation()

@kotlinx.serialization.Serializable
data class Lyrics(
    val placement: String,
    val align: String,
    val offset: Int
)

@kotlinx.serialization.Serializable
data class Lyric(
    val text: String
)

@kotlinx.serialization.Serializable
data class Rest(
    val duration: String
) : Notation()

@kotlinx.serialization.Serializable
data class Spacer(
    val width: Int
) : Notation()
