package ui

import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.geometry.Size
import androidx.compose.ui.graphics.drawscope.DrawScope
import androidx.compose.ui.graphics.drawscope.drawIntoCanvas
import androidx.compose.ui.graphics.drawscope.translate
import androidx.compose.ui.graphics.nativeCanvas
import androidx.compose.ui.graphics.painter.Painter
import androidx.compose.ui.unit.Density
import androidx.compose.ui.unit.TextUnit
import models.Notation
import models.Score
import models.Score.Staff
import models.toUnicode
import org.jetbrains.skia.Color
import org.jetbrains.skia.Font
import org.jetbrains.skia.Paint
import org.jetbrains.skia.PaintMode
import org.jetbrains.skia.Path
import org.jetbrains.skia.PathMeasure
import org.jetbrains.skia.Point
import org.jetbrains.skia.RSXform
import org.jetbrains.skia.Rect
import org.jetbrains.skia.TextBlob
import org.jetbrains.skia.TextBlobBuilder
import kotlin.math.max
import kotlin.math.min

class NotePainter2(
  val font: Font,
  val fontSize: TextUnit,
  staffs: List<Score.Staff>,
  val density: Density,
  val renderOffset: () -> Offset = { Offset.Zero },
) : Painter() {
  private var bounds = Rect(
    0f,
    0f,
    0f,
    0f
  )
  override val intrinsicSize: Size
    get() = Size(
      bounds.width,
      bounds.height
    )

  private val textBlob: TextBlob

  private val loadedGlyphs = mutableListOf<Glyph>()
  val staffPathSlurs = mutableMapOf<Staff, List<Path>>()


  init {
    val _visibleGlyphTransforms = mutableListOf<RSXform>()
    val _visibleGlyphs = mutableListOf<Short>()

    val noteStep = (fontSize / 4)
    val noteHalfStep = noteStep / 2
    val noteHalfStepPx = with(density) { noteHalfStep.toPx() }

    val fontPixels = with(density) { fontSize.toPx() }
    font.size = fontPixels

    val center = -noteStep * 2
    val centerTextDp = with(density) { center.toPx() }

    val defaultSpace = fontPixels / 2
    val keySignatureSpace = fontPixels / 12

    val scoreDurationMap = mutableMapOf<Int, MutableList<Pair<Staff, Notation>>>()
    staffs.forEach { staff ->
      staff.notes.scan(0) { noteOffset, notation ->
        if (scoreDurationMap[noteOffset] == null) {
          scoreDurationMap[noteOffset] = mutableListOf()
        }

        scoreDurationMap[noteOffset]!!.add(
          Pair(
            staff,
            notation
          )
        )

        noteOffset + notation.normalizedSize()
      }
    }


    val barWidth = font.glyphData("$BAR").glyphWidth()

    val staffSlurs = mutableMapOf<Staff, MutableList<Slur>>()
    val currentSlurs = mutableMapOf<Staff, Slur>()
    val minStaffOffsets = mutableMapOf<Staff, Float>()
    val scoreStaffGlyphs = mutableMapOf<Staff, Glyph>()
    var xOffset = 0F
    scoreDurationMap.entries.sortedBy { it.key }.forEach { (normalizedOffset, staffNote) ->
      staffNote.forEach {
        minStaffOffsets[it.first]?.let { minOffset ->
          if (xOffset < minOffset) {
            xOffset = minOffset
          }
        }
      }

      var maxWidth = 0F
      staffNote.forEach { (staff, notation) ->
        if (scoreStaffGlyphs[staff] == null) {
          scoreStaffGlyphs[staff] = Glyph()
        }

        val glyph = notation.createGlyph(
          font,
          fontSize,
          density
        )
        scoreStaffGlyphs[staff] = scoreStaffGlyphs[staff]!!.combine(
          glyph.offset(
            Point(
              xOffset,
              0F
            )
          )
        )

        val notationFrac = (notation.normalizedSize().toFloat() / "8th".normalizedLength().toFloat())
        val minSpace = glyph.totalBounds().width + (notationFrac * defaultSpace)
        minStaffOffsets[staff] = xOffset + max(
          1F,
          minSpace
        )

        if (notation.isSlur()) {
          println("###: slur: $notation")
          if (currentSlurs[staff] == null) {
            currentSlurs[staff] = Slur()
          }
          currentSlurs[staff]!!.notes.add(
            Pair(
              notation,
              glyph.offset(
                Point(
                  xOffset,
                  0F
                )
              )
            )
          )
        } else if (currentSlurs[staff] != null) {
          if (staffSlurs[staff] == null) {
            staffSlurs[staff] = mutableListOf()
          }

          currentSlurs[staff]!!.notes.add(Pair(notation, glyph.offset(
            Point(
              xOffset,
              0F
            )
          )))
          staffSlurs[staff]!!.add(currentSlurs[staff]!!)
          currentSlurs.remove(staff)
        }

        maxWidth = max(
          maxWidth,
          glyph.totalBounds().width
        )
      }
      xOffset += maxWidth + defaultSpace
    }

    currentSlurs.forEach { (staff, slur) ->
      if (staffSlurs[staff] == null) {
        staffSlurs[staff] = mutableListOf()
      }

      staffSlurs[staff]!!.add(slur)
    }
    currentSlurs.clear()

    staffSlurs.forEach { (staff, slurs) ->
      staffPathSlurs[staff] = slurs.map {
        Path().apply {
          val firstPosition = it.notes.first()
          val lastPosition = it.notes.last()
          val boundingBox = it.notes.map { it.second }.scan(firstPosition.second.totalBounds()) { acc, glyph ->
            Rect(
              min(
                acc.left,
                glyph.totalBounds().left
              ),
              min(
                acc.top,
                glyph.totalBounds().top
              ),
              max(
                acc.right,
                glyph.totalBounds().right
              ),
              max(
                acc.bottom,
                glyph.totalBounds().bottom
              )
            )
          }
          val firstPoint = Point(
            firstPosition.second.totalBounds().right - noteHalfStepPx,
            firstPosition.second.totalBounds().top
          )

          val lastPoint = Point(
            lastPosition.second.totalBounds().right - noteHalfStepPx,
            lastPosition.second.totalBounds().top
          )

          moveTo(firstPoint)
          cubicTo(
            Point(
              firstPoint.x + noteHalfStepPx * 4,
              firstPoint.y - (noteHalfStepPx * 4)
            ),
            Point(
              lastPoint.x - (noteHalfStepPx * 4),
              lastPoint.y - (noteHalfStepPx * 4)
            ),
            lastPoint
          )
        }
      }
    }


    val staffGlyph = font.glyphData(
      "\uE014",
      Point(
        0f,
        0f
      )
    )

    var pathY = 0F
    staffs.forEachIndexed { staffIndex, staff ->
      var glyphData = Glyph()
      var notationOffset = 0F

      val glyph = font.glyphData(
        "$BAR",
        Point(
          notationOffset,
          0F
        )
      )
      notationOffset += glyph.glyphWidth() + defaultSpace
      glyphData = glyphData.combine(glyph)

      val cleffType = staff.clef.type
      val cleffUnicode = if (cleffType == "Treble") TREBLE_CLEFF else BASS_CLEFF
      val cleffOffset = if (cleffType == "Treble") -noteStep else -noteStep * 3

      val cleff = font.glyphData(
        cleffUnicode,
        Point(
          notationOffset,
          with(density) { cleffOffset.toPx() })
      )
      notationOffset += cleff.glyphWidth() + defaultSpace
      glyphData = glyphData.combine(cleff)

      staff.key.getKeyPositions(staff.clef).forEach {
        val cleff = font.glyphData(
          it.first,
          Point(
            notationOffset,
            centerTextDp - noteHalfStepPx * it.second
          )
        )
        notationOffset += cleff.glyphWidth() + keySignatureSpace
        glyphData = glyphData.combine(cleff)
      }

      var zero = staff.timeSignature.top.toUnicode()
      var bars = font.glyphData(
        "\uE09E" + zero,
        Point(
          notationOffset,
          centerTextDp - noteHalfStepPx * 2
        )
      )
      glyphData = glyphData.combine(bars)

      zero = staff.timeSignature.bottom.toUnicode()
      bars = font.glyphData(
        "\uE09E" + zero,
        Point(
          notationOffset,
          centerTextDp + noteHalfStepPx * 2
        )
      )
      notationOffset += bars.glyphWidth() + defaultSpace
      glyphData = glyphData.combine(bars)

      glyphData = glyphData.combine(
        scoreStaffGlyphs[staff]!!.offset(
          Point(
            notationOffset,
            0F
          )
        )
      )

      staffPathSlurs[staff]?.let {
        staffPathSlurs[staff] = it.map {
          it.offset(
            notationOffset,
            pathY
          )
        }
      }

      val path = Path().apply {
        addPoly(
          arrayOf(
            Point(
              0f,
              pathY
            ),
            Point(
              glyphData.glyphPositions.last().x + glyphData.glyphWidths.last(),
              pathY
            )
          ),
          false
        )
      }

      bounds = glyphData.bounds.fold(bounds) { acc, rect ->
        Rect(
          min(
            acc.left,
            rect.left
          ),
          min(
            acc.top,
            rect.top + pathY
          ),
          max(
            acc.right,
            rect.right
          ),
          max(
            acc.bottom,
            rect.bottom + pathY
          )
        )
      }

      val pathMeasure = PathMeasure(path)
      val pathPixelLength = pathMeasure.length
      val textPixelLength =
        glyphData.glyphPositions[glyphData.glyphs.size - 1].x + glyphData.glyphWidths[glyphData.glyphs.size - 1]
      val textStartOffset = pathPixelLength - textPixelLength

      var staffOffset = 0f
      while (staffOffset < glyphData.totalBounds().width) {
        val staff = font.glyphData(
          "\uE014",
          Point(
            staffOffset,
            0f
          )
        )
        staffOffset += staff.glyphWidth()
        glyphData = glyphData.combine(staff)
      }

      for (index in glyphData.glyphs.indices) {
        val glyphStartOffset = glyphData.glyphPositions[index]
        val glyphWidth = glyphData.glyphWidths[index]
        val glyphMidPointOffset = textStartOffset + glyphStartOffset.x + glyphWidth / 2.0f
        if ((glyphMidPointOffset >= 0.0f) && (glyphMidPointOffset < pathPixelLength)) {
          val glyphMidPointOnPath = pathMeasure.getPosition(glyphMidPointOffset)!!
          val glyphMidPointTangent = pathMeasure.getTangent(glyphMidPointOffset)!!

          var translationX = glyphMidPointOnPath.x
          var translationY = glyphMidPointOnPath.y

          translationX -= glyphMidPointTangent.x * glyphWidth / 2.0f
          translationY += glyphData.glyphPositions[index].y

          _visibleGlyphTransforms.add(
            RSXform(
              scos = glyphMidPointTangent.x,
              ssin = glyphMidPointTangent.y,
              tx = translationX,
              ty = translationY
            )
          )

          _visibleGlyphs.add(glyphData.glyphs[index])
        }
      }

      if (!staff.properties.withNextStaff.contains("Layer")) {
        pathY = bounds.height
      }
    }

    textBlob = TextBlobBuilder().apply {
      appendRunRSXform(
        font,
        _visibleGlyphs.toShortArray(),
        _visibleGlyphTransforms.toTypedArray()
      )
    }.build()!!

    bounds = staffPathSlurs.values.flatten().map { it.bounds }.plus(bounds).union()
  }

  override fun DrawScope.onDraw() {

    translate(
      left = renderOffset().x,
      top = renderOffset().y + -bounds.top
    ) {
      drawIntoCanvas {
        it.nativeCanvas.drawTextBlob(
          textBlob,
          0f,
          0f,
          Paint()
        )


        staffPathSlurs.values.flatten().forEach { slurPath ->
          it.nativeCanvas.drawPath(
            slurPath,
            Paint().apply {
              mode = PaintMode.STROKE
              strokeWidth = 1F
              color = Color.BLACK
            })
        }

//                it.nativeCanvas.drawPath(path, Paint().apply {
//                    mode = PaintMode.STROKE
//                    color = Color.RED
//                    strokeWidth = 1F
//                })
//
//        it.nativeCanvas.drawRect(
//          bounds,
//          Paint().apply {
//            mode = PaintMode.STROKE
//            color = Color.MAGENTA
//            strokeWidth = 1F
//          })
//
//        loadedGlyphs.map { it.totalBounds() }.forEach { rect ->
//          it.nativeCanvas.drawRect(
//            rect,
//            Paint().apply {
//              mode = PaintMode.STROKE
//              color = Color.GREEN
//              strokeWidth = 1F
//            })
//        }
      }
    }
  }


}

class Slur {
  val notes = mutableListOf<Pair<Notation, Glyph>>()
}

fun List<Rect>.union() =
  fold(getOrElse(0) { RECT_ZERO }) { acc, rect ->
  Rect(
    min(
      acc.left,
      rect.left
    ),
    min(
      acc.top,
      rect.top
    ),
    max(
      acc.right,
      rect.right
    ),
    max(
      acc.bottom,
      rect.bottom
    )
  )
}
