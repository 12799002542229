package ui

import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.*
import androidx.compose.material.Button
import androidx.compose.material.Slider
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.PlayArrow
import androidx.compose.material.icons.filled.Settings
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.painter.Painter
import androidx.compose.ui.input.pointer.*
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.layout.LayoutCoordinates
import androidx.compose.ui.layout.onGloballyPositioned
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import dev.atsushieno.ktmidi.MidiOutput
import models.Score
import org.jetbrains.skia.Font
import sound.MusicPlayer
import kotlin.time.ExperimentalTime
import kotlin.time.measureTime

@OptIn(ExperimentalTime::class)
@Composable
fun ScorePlayer(
  modifier: Modifier,
  scoreFont: Font,
  score: Score,
  midiOutput: MidiOutput?,
  log: (String) -> Unit,
) {
  var layoutInfo by remember { mutableStateOf<LayoutCoordinates?>(null) }
  Column(modifier = modifier.onGloballyPositioned { layoutInfo = it }) {
    var invalidate by remember { mutableStateOf(0) }
    val density = LocalDensity.current
    var painter by remember(score) { mutableStateOf<Painter?>(null) }
    val musicPlayer by remember(score) {
      mutableStateOf(midiOutput?.let {
        MusicPlayer(
          score,
          it
        )
      })
    }
    var playing by remember(score) { mutableStateOf(false) }
    var normalizedPlayerPosition by remember(score) { mutableStateOf(0f) }
    val paddingOffset = with(density) { 24.dp.roundToPx() }
    val scoreOffset by remember(score) {
      derivedStateOf {
        if (painter == null) return@derivedStateOf Offset.Zero

        Offset(
          normalizedPlayerPosition * -painter!!.intrinsicSize.width + paddingOffset,
          0F
        )
      }
    }

    DisposableEffect(score) {
      val duration = measureTime {
        painter = NotePainter2(
          scoreFont,
          32.sp,
          score.staffs,
          density
        ) {
          scoreOffset
        }
      }

      console.log("Setup Painter: ${duration.inWholeMilliseconds}ms")
      onDispose {
        musicPlayer?.player?.stop()
      }
    }

//    println("###: MidiOutput? $midiOutput")

    if (playing) {
      musicPlayer?.let { musicPlayer ->
        normalizedPlayerPosition =
          musicPlayer.player.playDeltaTime.toFloat() / musicPlayer.player.totalPlayTimeMilliseconds
//                scoreOffset = scoreOffset.copy(x = -normalizedPlayerPosition * (painter?.intrinsicSize?.width ?: 0f))

        if (normalizedPlayerPosition == 1F) {
          playing = false
        }
      }
      invalidate += 1
    }

    painter?.let {
      Image(
        modifier = Modifier.weight(1F),
        painter = it,
        contentDescription = null,
        alignment = Alignment.TopStart,
        contentScale = ContentScale.None
      )
    } ?: Spacer(modifier = Modifier.weight(1f))

    Row {
      Button(
        onClick = {
          if (!playing) musicPlayer?.player?.play() else musicPlayer?.player?.pause(); if (musicPlayer != null) playing =
          !playing
        }
      ) {
        Image(
          imageVector = if (!playing) Icons.Default.PlayArrow else Icons.Default.Settings,
          contentDescription = ""
        )
      }


      Slider(
        value = normalizedPlayerPosition,
        onValueChange = { normalizedPlayerPosition = it },
        onValueChangeFinished = { musicPlayer?.player?.seek((normalizedPlayerPosition * musicPlayer!!.player.playDeltaTime).toInt()) }
      )


//            val radius = with (LocalDensity.current) { 5.dp.toPx() }
//            LinearProgressIndicator(
//                modifier = Modifier
//                    .weight(1f)
//                    .height(16.dp)
//                    .padding(horizontal = 5.dp)
//                    .drawWithContent {
//                        drawContent()
//                        drawCircle(Color.Gray, radius, center = Offset(normalizedPlayerPosition * size.width, size.height / 2f))
//                    }
//                    .onGloballyPositioned { layoutInfo = it }
//                    .pointerInput(Unit) {
//                        forEachGesture {
//                            awaitPointerEventScope {
//                                awaitFirstDown()
//                                do {
//                                    val event: PointerEvent = awaitPointerEvent()
//                                    event.changes.forEach { change: PointerInputChange ->
//                                        log("${event.type} : ${change.position}")
//                                        if (change.position.x >= 0F) {
//                                            normalizedPlayerPosition = change.position.x / layoutInfo!!.size.width.toFloat()
//                                            scoreOffset = Offset(
//                                                normalizedPlayerPosition * -painter!!.intrinsicSize.width,
//                                                0F
//                                            )
//                                            change.consumePositionChange()
//                                        }
//                                    }
//                                } while (event.changes.any { it.pressed })
//
//                                // ACTION_UP is here
//                            }
//                        }
//                    }
//                    ,
//                progress = normalizedPlayerPosition
//            )
    }
  }
}
