package ui

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.material.DrawerValue
import androidx.compose.material.Scaffold
import androidx.compose.material.TopAppBar
import androidx.compose.material.rememberDrawerState
import androidx.compose.material.rememberScaffoldState
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.geometry.Size
import androidx.compose.ui.geometry.toRect
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.Outline
import androidx.compose.ui.graphics.Shape
import androidx.compose.ui.unit.Density
import androidx.compose.ui.unit.LayoutDirection

@Composable
fun Browser(
  modifier: Modifier = Modifier,
  drawer: @Composable (() -> Unit),
  content: @Composable (() -> Unit),
) {
  val materialBlue700 = Color(0xFF1976D2)
  val scaffoldState = rememberScaffoldState(rememberDrawerState(DrawerValue.Open))

  Scaffold(
    modifier = modifier,
    scaffoldState = scaffoldState,
    topBar = {
      TopAppBar(
        title = { Text("Hymnal Browser") },
        backgroundColor = materialBlue700
      )
    },
    drawerShape = customShape(),
    drawerGesturesEnabled = true,
    drawerContent = {
      Box(modifier = Modifier.fillMaxWidth(DRAWER_PERCENT)) {
        drawer.invoke()
      }
    },
    content = { content.invoke() })
}

fun customShape() = object : Shape {
  override fun createOutline(
    size: Size,
    layoutDirection: LayoutDirection,
    density: Density,
  ): Outline {
    return Outline.Rectangle(
      size.copy(
        width = size.width * DRAWER_PERCENT
      ).toRect()
    )
  }
}

private const val DRAWER_PERCENT = .8F
