import kotlinx.serialization.*

@Serializable
@JsExport
data class HymnalDirectory(
    val hymnals: List<Hymnal>
)

@Serializable
@JsExport
data class Hymnal(
    val name: String,
    val hymns: Map<String, Hymn>
) {

    @Serializable
    data class Hymn(
        val composer: Composer? = null,
        val copyright: Copyright? = null,
        val lyracist: Lyracists? = null,
        val lyric: Lyric? = null,
        val hymnTxtPath: String? = null
    )

    @Serializable
    data class Composer(
        val hymnNumber: String,
        val metrical: String,
        val firstLine: String,
        val source: String,
        val action: String,
        val composer1: String,
        val action1: String,
        val composer2: String,
        val action2: String,
        val composer3: String,
        val action3: String,
        val composer4: String,
        val action4: String,
        val composer5: String
    )

    @Serializable
    data class Copyright(
        val hymnNumber: String,
        val textCR: String,
        val settingCR: String,
        val harmonizationCR: String,
        val individualCR: String
    )

    @Serializable
    data class Lyracists(
        val hymnNumber: String,
        val action: String,
        val source: String,
        val action1: String,
        val translator1: String,
        val action2: String,
        val translator2: String,
        val action3: String,
        val translator3: String,
        val action4: String,
        val translator4: String,
        val scripture: String
    )

    @Serializable
    data class Lyric(val hymnNumber: String, val firstLine: String, val fullText: String)
}