
import androidx.compose.foundation.layout.BoxWithConstraints
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.size
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.NoLiveLiterals
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.geometry.Size
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.text.font.FontFamily
import androidx.compose.ui.text.platform.Font
import androidx.compose.ui.unit.dp
import androidx.compose.ui.window.Window
import dev.atsushieno.ktmidi.MidiOutput
import kotlinx.browser.window
import kotlinx.coroutines.MainScope
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json
import models.NwcTxtParser
import models.Score
import org.jetbrains.skia.Data
import org.jetbrains.skia.Font
import org.jetbrains.skia.Point
import org.jetbrains.skia.Typeface
import org.jetbrains.skiko.wasm.onWasmReady
import org.khronos.webgl.ArrayBuffer
import org.khronos.webgl.Int8Array
import org.w3c.dom.HTMLCanvasElement
import org.w3c.dom.TouchEvent
import org.w3c.dom.events.MouseEvent
import org.w3c.dom.events.MouseEventInit
import org.w3c.dom.get
import org.w3c.workers.ServiceWorkerGlobalScope
import org.w3c.xhr.ARRAYBUFFER
import org.w3c.xhr.XMLHttpRequest
import org.w3c.xhr.XMLHttpRequestResponseType
import ui.Browser
import ui.ScorePlayer
import kotlin.js.json
import kotlin.math.max
import kotlin.time.ExperimentalTime
import kotlin.time.measureTime

external val self: ServiceWorkerGlobalScope
val scope = MainScope()

@OptIn(
  ExperimentalTime::class
)
@NoLiveLiterals
fun main() {
  val access = JzzMidiAccess.create(true)
  var invalidate by mutableStateOf(0)
  var innerSize by mutableStateOf(Size.Zero)
  var log by mutableStateOf("")
  window.onresize = {
    innerSize = Size(
      window.innerWidth.toFloat(),
      window.innerHeight.toFloat()
    )
    Unit
  }

  if (window.navigator.asDynamic().requestMIDIAccess) {
    window.navigator.asDynamic().requestMIDIAccess(json(Pair("sysex", true)))
  } else {
    console.log("WebMIDI is not supported in this browser.");
  }

  window.onload = {
    innerSize = Size(
      window.innerWidth.toFloat(),
      window.innerHeight.toFloat()
    )
    Unit
  }

  window.onerror = { b: dynamic, s: String, i: Int, i1: Int, any: Any? ->
    invalidate++
    Unit
  }

  onWasmReady {
    val canvas = window.document.getElementById("ComposeTarget") as HTMLCanvasElement
    canvas.width = max(
      window.screen.width,
      window.screen.height
    )
    canvas.height = max(
      window.screen.width,
      window.screen.height
    )

    listOf(
      "touchstart" to "mousedown",
      "touchend" to "mouseup",
      "touchmove" to "mousemove"
    ).forEach { (from, to) ->
      window.document.body?.addEventListener(
        from,
        {
          if (it.target == canvas) {
            var rect = canvas.getBoundingClientRect()
            var point = Point(
              ((it as TouchEvent).changedTouches.get(0)?.clientX ?: 0 - rect.left).toFloat(),
              ((it as TouchEvent).changedTouches.get(0)?.clientY ?: 0 - rect.top).toFloat(),
            )

            var mouseEvent = MouseEvent(
              to,
              MouseEventInit(
                clientX = point.x.toInt(),
                clientY = point.y.toInt(),
                buttons = if (from == "touchend") 0 else 1
              )
            )
            canvas.dispatchEvent(mouseEvent)
            it.preventDefault()
          }
        })
    }


    Window("Hymnal Browser") {
      if (window.navigator.serviceWorker != null) {
          window.navigator.serviceWorker.register("/serviceWorker.js")
            .then { console.log("Service worker registered!") }
            .catch { console.error("Service Worker registration failed: $it") }
      }

      val clip = with(LocalDensity.current) { innerSize.toDpSize() }
      BoxWithConstraints(modifier = Modifier.size(clip)) {
        var hymnalDirectory by remember { mutableStateOf<HymnalDirectory?>(null) }
        var font by remember { mutableStateOf<FontFamily?>(null) }
        var skiaFont by remember { mutableStateOf<Font?>(null) }

        LaunchedEffect(Unit) {
          XMLHttpRequest().apply {
            open(
              "GET",
              "/directory.json"
            )
            onloadend = {
              hymnalDirectory = Json.decodeFromString(responseText)
              Unit
            }
            send()
          }

          XMLHttpRequest().apply {
            responseType = XMLHttpRequestResponseType.Companion.ARRAYBUFFER
            open(
              "GET",
              "/Bravura.otf"
            )
            onloadend = {
              val bytes = (response as ArrayBuffer).toByteArray()
              font = FontFamily(
                Font(
                  "Bravura",
                  bytes
                )
              )

              skiaFont = Font(Typeface.makeFromData(Data.makeFromBytes(bytes)))
              Unit
            }
            send()
          }
        }

        var selectedHymn by remember { mutableStateOf<Hymnal.Hymn?>(null) }
        var scoreText by remember { mutableStateOf<String?>(null) }
        var score by remember { mutableStateOf<Score?>(null) }

        selectedHymn?.let {
          LaunchedEffect(it) {
            XMLHttpRequest().apply {
              println("###: $it")
              open(
                "GET",
                it.hymnTxtPath!!
              )
              onloadend = {
                scoreText = responseText
                Unit
              }
              send()
            }
          }

          LaunchedEffect(scoreText) {
            console.log("Start Parse")
            val duration = measureTime {
              scoreText?.let {
                score = NwcTxtParser(it).parse()
              }
            }

            console.log("End Parse: ${duration.inWholeMilliseconds}")
          }
        }

        hymnalDirectory?.let {
          Browser(
            modifier = Modifier.fillMaxSize(),
            drawer = {
              BoxWithConstraints {
                if (maxWidth > 600.dp) {
                  FullHymnalBrowser(
                    hymnals = it.hymnals,
                    onPlayHymn = { selectedHymn = it },
                    onCopyToClipboard = { },
                    onOpenLink = { }
                  )
                } else {
                  CompactHymnalBrowser(
                    hymnals = it.hymnals,
                    onPlayHymn = { selectedHymn = it },
                    onCopyToClipboard = { },
                    onOpenLink = { }
                  )
                }
              }
            },
            content = {
              font?.let {
                var output by remember(score) {
                  mutableStateOf<Pair<Boolean, MidiOutput?>>(
                    Pair(
                      false,
                      null
                    )
                  )
                }
                LaunchedEffect(score) {
                  output = Pair(
                    true,
                    access.outputs.firstOrNull()?.let {
                      println("###: Found Port: $it : ${JSON.stringify(it)}")
                      access.openOutput(it.id)
                    })
                }
                score?.let {
                  if (output.first) {
                    ScorePlayer(
                      modifier = Modifier.fillMaxSize(),
                      scoreFont = skiaFont!!,
                      score = it.copy(
                        staffs = it.staffs.filter { it.properties.visible }.toMutableList()
                      ),
                      midiOutput = output.second,
                      log = { log = it }
                    )
                  }
                }
              }
            }
          )
        }

        val split = log.split("\n")
        if (split.size > 20) {
          log = split.subList(
            split.size - 20,
            split.size
          ).joinToString("\n")
        }

//        Text(
//          modifier = Modifier.align(Alignment.BottomCenter).fillMaxWidth().heightIn(max = 512.dp).background(
//            Color.Gray.copy(alpha = .25F)
//          ),
//          text = log
//        )
      }
    }
  }
}

fun ArrayBuffer.toByteArray(): ByteArray = Int8Array(this).unsafeCast<ByteArray>()

const val TEST =
  "{\"hymnals\":[{\"name\":\"Lift Every Voice And Sing\",\"hymns\":{\"8\":{\"composer\":{\"hymnNumber\":\"8\",\"metrical\":\"\",\"firstLine\":\"Deep river, my home is over Jordan\",\"source\":\"Negro Spiritual\",\"action\":\"arr.\",\"composer1\":\"Carl Haywood (b. 1949)\",\"action1\":\"from\",\"composer2\":\"The Haywood Collection of Negro Spirituals\",\"action2\":\"\",\"composer3\":\"\",\"action3\":\"\",\"composer4\":\"\",\"action4\":\"\",\"composer5\":\"\"},\"copyright\":{\"hymnNumber\":\"8\",\"textCR\":\"\",\"settingCR\":\"\",\"harmonizationCR\":\"\",\"individualCR\":\"Carl Haywood Copyright © 1992\"},\"lyracist\":{\"hymnNumber\":\"8\",\"action\":\"\",\"source\":\"Traditional\",\"action1\":\"\",\"translator1\":\"\",\"action2\":\"\",\"translator2\":\"\",\"action3\":\"\",\"translator3\":\"\",\"action4\":\"\",\"translator4\":\"\",\"scripture\":\"\"}}}}]}"
//
//    ?: run {
//      Text(
//        modifier = Modifier.fillMaxSize().drawWithCache {
//          val defaultFont = FontCollection().apply {
//            setDefaultFontManager(TypefaceFontProvider().apply {
//              registerTypeface(skiaFont!!.typeface!!, "Bravura")
//            }, "Bravura")
//          }
//
//          val start = '\uE8E4'
//          val end = '\uE8E5'
//          val quarter = '\uE1D5'
//          val offset = '\uEB90'
//          val offset2 ='\uEB93'
//
//          for (i in 0 until FontMgr.default.familiesCount) {
//            println("###: Font: ${FontMgr.default.getFamilyName(i)}")
//          }
//
//          var paragraphBuilder = ParagraphBuilder(ParagraphStyle(), defaultFont)
//          paragraphBuilder.pushStyle(
//            TextStyle().apply {
//              setColor(Color.BLACK)
//              setFontFamily("Bravura")
//              setFontSize(32F)
//            }
//          )
//          val paragraph = paragraphBuilder.build().layout(1000F)
//          println("###: ${paragraph.maxWidth} : ${paragraph.height} : $size")
//          onDrawWithContent {
//            drawIntoCanvas {
//              skiaFont!!.size = 64F
//
//
//
//              val line = TextLine.make(charArrayOf(start, offset, quarter, offset2, quarter, end).concatToString(), skiaFont!!)
//              it.nativeCanvas.drawTextLine(line, 0F, 100F, Paint())
////                          paragraph.paint(it.nativeCanvas, 0F, 0F)
//            }
//          }
//        },
//        text = "",
//        fontSize = 32.sp,
//        fontFamily = font!!
//      )
//    }
