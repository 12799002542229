package ui

import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.geometry.Size
import androidx.compose.ui.graphics.drawscope.DrawScope
import androidx.compose.ui.graphics.drawscope.drawIntoCanvas
import androidx.compose.ui.graphics.drawscope.translate
import androidx.compose.ui.graphics.nativeCanvas
import androidx.compose.ui.graphics.painter.Painter
import androidx.compose.ui.unit.Density
import androidx.compose.ui.unit.TextUnit
import androidx.compose.ui.unit.dp
import models.Bar
import models.Chord
import models.Notation
import models.Note
import models.NoteOptions
import models.Rest
import models.Score
import models.Spacer
import models.Text
import org.jetbrains.skia.Font
import org.jetbrains.skia.Paint
import org.jetbrains.skia.Path
import org.jetbrains.skia.PathMeasure
import org.jetbrains.skia.Point
import org.jetbrains.skia.RSXform
import org.jetbrains.skia.Rect
import org.jetbrains.skia.TextBlob
import org.jetbrains.skia.TextBlobBuilder
import kotlin.math.max
import kotlin.math.min

class NotePainter(
  val font: Font,
  val fontSize: TextUnit,
  staffs: List<Score.Staff>,
  val density: Density,
  val renderOffset: () -> Offset = { Offset.Zero },
) : Painter() {
  private var bounds = Rect(
    0f,
    0f,
    0f,
    0f
  )
  override val intrinsicSize: Size
    get() = Size(
      bounds.width,
      bounds.height
    )

  private val textBlob: TextBlob

  private val loadedGlyphs = mutableListOf<Glyph>()


  init {
    val _visibleGlyphTransforms = mutableListOf<RSXform>()
    val _visibleGlyphs = mutableListOf<Short>()

    val noteStep = (fontSize / 4)
    val noteHalfStep = noteStep / 2
    val noteHalfStepPx = with(density) { noteHalfStep.toPx() }

    val fontPixels = with(density) { fontSize.toPx() }
    font.size = fontPixels

    val center = -noteStep * 2
    val centerTextDp = with(density) { center.toPx() }

    val defaultSpace = fontPixels / 4
    val keySignatureSpace = fontPixels / 12

    val scoreNotes = staffs.map {
      it.notes.toMutableList()
    }

    val staffMeasures = scoreNotes.map {
      val measures = mutableListOf<Measure>()
      val noteGlyphs = mutableListOf<Notation>()
      it.forEach {
        noteGlyphs.add(it)

        if (it is Bar) {
          measures.add(
            Measure(font, fontSize, density, noteGlyphs.toList())
          )
          noteGlyphs.clear()
        }
      }
      if (noteGlyphs.isNotEmpty()) {
        measures.add(
          Measure(font, fontSize, density, noteGlyphs.toList())
        )
        noteGlyphs.clear()
      }
      measures.toList()
    }

    val staffGlyph = font.glyphData(
      "\uE014",
      Point(
        0f,
        0f
      )
    )

    val maxNotesInMeasure = scoreNotes.map {
      val measures = mutableListOf<List<Notation>>()
      val currentMeasure = mutableListOf<Notation>()
      it.forEach {
        currentMeasure.add(it)
        if (it is Bar) {
          measures.add(currentMeasure.toList())
          currentMeasure.clear()
        }
      }
      if (currentMeasure.isNotEmpty()) {
        measures.add(currentMeasure.toList())
        currentMeasure.clear()
      }
      measures.toList()
    }

    staffs.forEachIndexed { staffIndex, staff ->  }


    var pathY = 0F
    staffs.forEachIndexed { staffIndex, staff ->
      var glyphData = Glyph()
      var notationOffset = 0F


      val glyph = font.glyphData(
        "$BAR",
        Point(
          notationOffset,
          0F
        )
      )
      notationOffset += glyph.glyphWidth() + defaultSpace
      glyphData = glyphData.combine(glyph)

      val cleffType = staff.clef.type
      val cleffUnicode = if (cleffType == "Treble") TREBLE_CLEFF else BASS_CLEFF
      val cleffOffset = if (cleffType == "Treble") -noteStep else -noteStep * 3

      val cleff = font.glyphData(
        cleffUnicode,
        Point(
          notationOffset,
          with(density) { cleffOffset.toPx() })
      )
      notationOffset += cleff.glyphWidth() + defaultSpace
      glyphData = glyphData.combine(cleff)

      staff.key.getKeyPositions(staff.clef).forEach {
        val cleff = font.glyphData(
          it.first,
          Point(
            notationOffset,
            centerTextDp - noteHalfStepPx * it.second
          )
        )
        notationOffset += cleff.glyphWidth() + keySignatureSpace
        glyphData = glyphData.combine(cleff)
      }

      var bars = font.glyphData(
        "\uE09E\uE084",
        Point(
          notationOffset,
          centerTextDp - noteHalfStepPx * 2
        )
      )
      glyphData = glyphData.combine(bars)

      bars = font.glyphData(
        "\uE09E\uE084",
        Point(
          notationOffset,
          centerTextDp + noteHalfStepPx * 2
        )
      )
      notationOffset += bars.glyphWidth() + defaultSpace
      glyphData = glyphData.combine(bars)


      staffMeasures[staffIndex].forEachIndexed { index, measure ->
        val allStaffCurrentMeasure = staffMeasures.mapNotNull { if (index < it.size) it[index] else null }
        val longestMeasure = allStaffCurrentMeasure.map {
          it.noteGlyphs.sumOf { it.second.totalBounds().width + defaultSpace.toDouble() }
        }.max()


      }

      val staffMeasures = maxNotesInMeasure[staffIndex]
      staff.notes.forEachIndexed { index, notation ->
        var measure = -1
        var tempNoteIndex = index
        staffMeasures.forEachIndexed { index, notations ->
          if (measure == -1) {
            tempNoteIndex -= notations.size
            if (tempNoteIndex <= 0) {
              measure = index
            }
          }
        }

        val staffMeasures = mutableListOf<List<Notation>>()
        for (i in scoreNotes.indices) {
          val currentMeasures = maxNotesInMeasure[i]
          if (currentMeasures.size > measure) {
            staffMeasures.add(
              currentMeasures[measure]
            )
          }
        }

        val shortestNoteDenomination = staffMeasures.map { it.map {
          when (it) {
            is Chord -> min(it.duration.normalizedLength(), it.duration2.normalizedLength())
            is Note -> it.duration.normalizedLength()
            is Rest -> it.duration.normalizedLength()
            else -> Int.MAX_VALUE
          }
        }.min() }.min()


        val glyph = notation.createGlyph(font, fontSize, density)
        val noteGlyph = glyph.offset(Point(notationOffset, 0F))
        glyphData = glyphData.combine(noteGlyph)

        val noteSize = if (notation.normalizedSize() < shortestNoteDenomination) 1F else (notation.normalizedSize().toFloat() / shortestNoteDenomination.toFloat())
//          println("###: Notes: ${notation.normalizedSize()} : $shortestNoteDenomination")
        val minNoteSize = noteSize * (staffGlyph.glyphWidth() + defaultSpace)
        //        loadedGlyphs.add(noteGlyph.offset(Point(0F, pathY)))
//        println("###: noteOffset: $minNoteSize")
        notationOffset += minNoteSize
      }

      val path = Path().apply {
        addPoly(
          arrayOf(
            Point(
              0f,
              pathY
            ),
            Point(
              glyphData.glyphPositions.last().x + glyphData.glyphWidths.last(),
              pathY
            )
          ),
          false
        )
      }

      bounds = glyphData.bounds.fold(bounds) { acc, rect ->
        Rect(
          min(
            acc.left,
            rect.left
          ),
          min(
            acc.top,
            rect.top + pathY
          ),
          max(
            acc.right,
            rect.right
          ),
          max(
            acc.bottom,
            rect.bottom + pathY
          )
        )
      }

      val pathMeasure = PathMeasure(path)
      val pathPixelLength = pathMeasure.length
      val textPixelLength =
        glyphData.glyphPositions[glyphData.glyphs.size - 1].x + glyphData.glyphWidths[glyphData.glyphs.size - 1]
      val textStartOffset = pathPixelLength - textPixelLength

      var staffOffset = 0f
      while (staffOffset < glyphData.totalBounds().width) {
        val staff = font.glyphData(
          "\uE014",
          Point(
            staffOffset,
            0f
          )
        )
        staffOffset += staff.glyphWidth()
        glyphData = glyphData.combine(staff)
      }

      for (index in glyphData.glyphs.indices) {
        val glyphStartOffset = glyphData.glyphPositions[index]
        val glyphWidth = glyphData.glyphWidths[index]
        val glyphMidPointOffset = textStartOffset + glyphStartOffset.x + glyphWidth / 2.0f
        if ((glyphMidPointOffset >= 0.0f) && (glyphMidPointOffset < pathPixelLength)) {
          val glyphMidPointOnPath = pathMeasure.getPosition(glyphMidPointOffset)!!
          val glyphMidPointTangent = pathMeasure.getTangent(glyphMidPointOffset)!!

          var translationX = glyphMidPointOnPath.x
          var translationY = glyphMidPointOnPath.y

          translationX -= glyphMidPointTangent.x * glyphWidth / 2.0f
          translationY += glyphData.glyphPositions[index].y

          _visibleGlyphTransforms.add(
            RSXform(
              scos = glyphMidPointTangent.x,
              ssin = glyphMidPointTangent.y,
              tx = translationX,
              ty = translationY
            )
          )

          _visibleGlyphs.add(glyphData.glyphs[index])
        }
      }

      if (!staff.properties.withNextStaff.contains("Layer")) {
        pathY = bounds.height
      }
    }

    textBlob = TextBlobBuilder().apply {
      appendRunRSXform(
        font,
        _visibleGlyphs.toShortArray(),
        _visibleGlyphTransforms.toTypedArray()
      )
    }.build()!!
  }

  override fun DrawScope.onDraw() {

    translate(
      left = renderOffset().x,
      top = renderOffset().y + -bounds.top
    ) {
      drawIntoCanvas {
        it.nativeCanvas.drawTextBlob(
          textBlob,
          0f,
          0f,
          Paint()
        )
//                it.nativeCanvas.drawPath(path, Paint().apply {
//                    mode = PaintMode.STROKE
//                    color = Color.RED
//                    strokeWidth = 1F
//                })
//
//        it.nativeCanvas.drawRect(
//          bounds,
//          Paint().apply {
//            mode = PaintMode.STROKE
//            color = Color.MAGENTA
//            strokeWidth = 1F
//          })
//
//        loadedGlyphs.map { it.totalBounds() }.forEach { rect ->
//          it.nativeCanvas.drawRect(
//            rect,
//            Paint().apply {
//              mode = PaintMode.STROKE
//              color = Color.GREEN
//              strokeWidth = 1F
//            })
//        }
      }
    }
  }


}

fun Bar.toGlyph(font: Font, offset: Point = Point.ZERO) = font.glyphData(
  BAR,
  offset
)

fun Rest.toGlyph(font: Font, centerStaff: Float, offset: Point = Point.ZERO) = font.glyphData(
  toUnicode(),
  Point(
    offset.x,
    centerStaff + offset.y
  )
)

fun Chord.toGlyph(font: Font, centerStaff: Float, noteHalfStep: Float, defaultSpace: Float, offset: Point = Point.ZERO): Glyph {
  var glyph = Glyph()
  var offsetX = 0F
  positions.forEach { position ->
    val positionGlyph = toGlyph(
      font,
      centerStaff,
      noteHalfStep,
      position,
      duration,
      noteOptions,
      Point(
        offset.x + offsetX,
        offset.y
      )
    )
    glyph = glyph.combine(positionGlyph)
  }

  offsetX += glyph.glyphWidth() + defaultSpace

  positions2.forEach { position ->
    val positionGlyph = toGlyph(
      font,
      centerStaff,
      noteHalfStep,
      position,
      duration2,
      noteOptions,
      Point(
        offset.x + offsetX,
        offset.y
      )
    )
    glyph = glyph.combine(positionGlyph)
  }

  return glyph
}

fun Note.toGlyph(font: Font, centerStaff: Float, noteHalfStep: Float, offset: Point = Point.ZERO): Glyph {
  return toGlyph(
    font,
    centerStaff,
    noteHalfStep,
    position,
    duration,
    noteOptions,
    offset
  )
}

fun toGlyph(
  font: Font,
  centerStaff: Float,
  noteHalfStep: Float,
  position: String,
  duration: String,
  noteOptions: NoteOptions?,
  offset: Point = Point.ZERO,
): Glyph {
  val internalOffset = 0F
  val unModifierPosition = (if (position.startsWith("n") ||
    position.startsWith("b") ||
    position.startsWith("#")
  ) position.substring(1) else position)

  var unModifiedEnd =
    if (unModifierPosition.last() == ')')
      unModifierPosition.substring(
        startIndex = 0,
        endIndex = unModifierPosition.length - 1
      )
    else
      unModifierPosition

  unModifiedEnd = if (unModifiedEnd.last() == '^') unModifiedEnd.substring(
    startIndex = 0,
    endIndex = unModifiedEnd.length - 1
  ) else unModifiedEnd

  val staffPosition = -unModifiedEnd.toInt()
  val stemUp = staffPosition > 0
  val staffOffset = staffPosition * noteHalfStep

  return font.glyphData(
    toUnicode(
      stemUp,
      duration,
      noteOptions
    ),
    Point(
      x = offset.x + internalOffset,
      y = offset.y + centerStaff + staffOffset
    )
  )
}

fun Notation.createGlyph(font: Font, fontSize: TextUnit, density: Density): Glyph {
  val noteStep = (fontSize / 4)
  val noteHalfStep = noteStep / 2
  val noteHalfStepPx = with(density) { noteHalfStep.toPx() }

  val fontPixels = with(density) { fontSize.toPx() }
  font.size = fontPixels

  val center = -noteStep * 2
  val centerStaffPx = with(density) { center.toPx() }
  val defaultSpace = fontPixels / 3
  return when (this) {
        is Bar -> toGlyph(font)
        is Chord -> toGlyph(font, centerStaffPx, noteHalfStepPx, defaultSpace)
        is Note -> toGlyph(font, centerStaffPx, noteHalfStepPx)
        is Rest -> toGlyph(font, centerStaffPx)
        is Spacer -> Glyph(bounds = arrayOf(Rect(0F, 0F, with(density) { width.dp.toPx() }, 0F)))
        is Text -> Glyph()
  }
}

fun List<Notation>.createGlyph(font: Font, fontSize: TextUnit, density: Density): Glyph {
  val fontPixels = with(density) { fontSize.toPx() }
  font.size = fontPixels
  val defaultSpace = fontPixels / 3
  return this
    .map { note ->
      note.createGlyph(font, fontSize, density)
    }
    .fold(Glyph()) { acc, glyph ->
      val startOffset = acc.totalBounds().right
      val offset = Point(
        startOffset + defaultSpace,
        0F
      )
      acc.combine(
        glyph.offset(offset)
      )
    }
}

fun Font.glyphData(
  str: String,
  offset: Point = Point.ZERO,
): Glyph {
  var glyphs = getStringGlyphs(str)
  var glyphWidths = getWidths(glyphs)
  var glyphPositions = getPositions(
    glyphs,
    offset
  )

  val bounds = getBounds(
    glyphs,
    Paint()
  ).map {
    it.offset(
      offset.x,
      offset.y
    )
  }

  return Glyph(
    glyphs,
    glyphWidths,
    glyphPositions,
    bounds.toTypedArray()
  )
}


class Glyph(
  val glyphs: ShortArray = shortArrayOf(),
  val glyphWidths: FloatArray = floatArrayOf(),
  val glyphPositions: Array<Point> = arrayOf(),
  val bounds: Array<Rect> = arrayOf(),
) {
  fun combine(other: Glyph) =
    Glyph(
      shortArrayOf(
        *glyphs,
        *other.glyphs
      ),
      floatArrayOf(
        *glyphWidths,
        *other.glyphWidths
      ),
      arrayOf(
        *glyphPositions,
        *other.glyphPositions
      ),
      arrayOf(
        *bounds,
        *other.bounds
      )
    )

  fun glyphWidth() =
    glyphWidths.sum()

  fun totalBounds() = bounds.fold(bounds.getOrNull(0) ?: RECT_ZERO) { acc, rect ->
    Rect(
      left = min(acc.left, rect.left),
      top = min(acc.top, rect.top),
      right = max(acc.right, rect.right),
      bottom = max(acc.bottom, rect.bottom)
    )
  }


  fun offset(point: Point) =
    Glyph(
      glyphs,
      glyphWidths,
      glyphPositions.map {
        Point(
          it.x + point.x,
          it.y + point.y
        )
      }.toTypedArray(),
      bounds.map {
        it.offset(
          point.x,
          point.y
        )
      }.toTypedArray()
    )
}

fun Notation.normalizedSize() =
  when (this) {
    is Chord -> if (duration2.isBlank()) duration.normalizedLength() else min(duration.normalizedLength(), duration2.normalizedLength())
    is Note -> duration.normalizedLength()
    is Rest -> duration.normalizedLength()
    else -> 1
  }

fun Notation.isSlur() =
  when (this) {
    is Chord -> (duration.contains("Slur") || duration2.contains("Slur"))
    is Note -> (duration.contains("Slur"))
    else -> false
  }

class Measure(font: Font, textSize: TextUnit, density: Density, notes: List<Notation>) {

  val noteGlyphs by lazy {
    notes.map {
      Pair(it, it.createGlyph(font, textSize, density))
    }
  }


}


const val BAR = "\uE030"
const val BASS_CLEFF = "\uE062"
const val TREBLE_CLEFF = "\uE050"
val RECT_ZERO = Rect(
  0F,
  0F,
  0F,
  0F
)
